// 引入封装的axios请求文件
import req from '../utils/request.js'

// statement list
export function statements (params) {
  return req.post('statements', params)
}

// statement list
export function statementDetail (params) {
  return req.post('statementDetail', params)
}

// Adjustbills
export function statementAdjustbills (params) {
  return req.post('statementAdjustbills', params)
}

// downloadAdjustbills
export function downloadAdjustbills (params) {
  return req.post('downloadAdjustbills', params)
}
